import { h } from 'preact';
import { useState } from 'preact/hooks';
import classNames from 'classnames';
import websocket from './websocket'

import styles from './Controller.css';

export default function Controller() {
  function onClickA(event) {
    event.preventDefault();
    websocket.send('B0');
  }

  function onClickB(event) {
    event.preventDefault();
    websocket.send('B2');
  }

  function onClickX(event) {
    event.preventDefault();
    websocket.send('B1');
  }

  function onClickY(event) {
    event.preventDefault();
    websocket.send('B3');
  }

  return (
    <div className={styles.container}>
      <button className={classNames(styles.button, styles['button-top'])} onclick={onClickY} />
      <div>
        <button className={classNames(styles.button, styles['button-left'])} onclick={onClickB} />
        <button className={classNames(styles.button, styles['button-right'])} onclick={onClickX} />
      </div>
      <button className={classNames(styles.button, styles['button-bottom'])} onclick={onClickA} />
    </div>
  );
}
