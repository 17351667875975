import { h } from 'preact';
import { useRef, useState } from 'preact/hooks';
import classNames from 'classnames';

import styles from './GameInput.css';

export default function GameInput(props) {
  const gameRef = useRef('');
  const [game, setGame] = useState('');

  function onClick(event) {
    event.preventDefault();
    if (gameRef.current) {
      const requestedGame = gameRef.current.value.toUpperCase();
      setGame(requestedGame);
      props.onSubmit(requestedGame);
    }
  }

  function onInput(event) {
    if (gameRef.current) {
      const requestedGame = gameRef.current.value.toUpperCase();;
      setGame(requestedGame);
      props.onInput(requestedGame);
    }
  }

  return (
    <form className={styles.form} autocomplete="off">
      <label className={styles.label} for="game">Join Game</label>
      <input className={classNames(styles.input, { [styles['input-error']]: props.error })} oninput={onInput} ref={gameRef} type="text" id="game" name="game" maxlength="6" placeholder="Enter 6-letter code" value={game} autocapitalize="off" autocorrect="off" autocomplete="off" />
      <button className={styles.button} onclick={onClick} disabled={props.disabled}>Ready</button>
    </form>
  );
}
