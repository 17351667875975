let websocket = null
let gameId = '';
let masterConnectionId = null;

let onGameValid = null;
let onGameInValid = null;

function onOpen(event) {
  console.log(gameId);
  websocket.send('S:' + gameId);
}

function onMessage(event) {
  console.log(event.data);

  if (event.data && event.data === 'S:!') {
    if (onGameInValid) { onGameInValid(gameId); }
    return;
  }

  if (event.data && event.data.startsWith('S:')) {
    const message = event.data.substring(2);
    const messages = message.split(',');
    if (messages.length === 2 && messages[0] === gameId) {
      gameId = messages[0];
      masterConnectionId = messages[1];
      if (onGameValid) { onGameValid(gameId); }
    }
  }
}

function connect(game) {
  gameId = game;

  if (websocket) {
    websocket.onopen = null;
    websocket.onmessage = null;
    websocket.close();
    websocket = null;
  }

  websocket = new WebSocket("wss://cnb0hvr7u2.execute-api.us-east-1.amazonaws.com/dev/");
  websocket.onopen = onOpen;
  websocket.onmessage = onMessage;
}

function send(action) {
  if (websocket) {
    websocket.send('A:' + gameId + ',' + masterConnectionId + ',' + action);
  }
}

export default {
  get connect() { return connect },
  get send() { return send },
  get onGameValid() { return onGameValid; },
  set onGameValid(value) { onGameValid = value; },
  get onGameInValid() { return onGameInValid; },
  set onGameInValid(value) { onGameInValid = value; },
};
