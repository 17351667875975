import { h } from 'preact';
import { useState } from 'preact/hooks';
import websocket from './websocket'

import GameInput from './GameInput'
import Controller from './Controller'

import styles from './App.css';

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [gameInputDisabled, setGameInputDisabled] = useState(false);
  const [gameInputError, setGameInputError] = useState(false);

  function onGameValid(game) {
    setIsLoggedIn(true);
  }

  function onGameInValid(game) {
    setGameInputDisabled(false);
    setGameInputError(true);
  }

  function connectToGame(game) {
    setGameInputDisabled(true);
    websocket.onGameInValid = onGameInValid;
    websocket.onGameValid = onGameValid;
    websocket.connect(game);
  }

  return (
    <div className={styles.container}>
      {!isLoggedIn && (
        <GameInput onSubmit={connectToGame} onInput={() => { setGameInputError(false); }} error={gameInputError} disabled={gameInputDisabled} />
      )}
      {isLoggedIn && (
        <Controller />
      )}
    </div>
  );
}
